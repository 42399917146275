module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NWMSJRS","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Koelewijn Vishandel","short_name":"Koelewijn Vishandel","start_url":"/","background_color":"#15428E","theme_color":"#15428E","display":"standalone","icon":"src/img/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d3985ffc5a6d96061e272e84a49868d3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Bebas+Neue:400"]},"custom":{"families":["Helvetica LT W01 Roman","Helvetica LT W01 Bold"],"urls":["https://fast.fonts.net/cssapi/a20ebd32-b45b-4cf2-b92e-78c3756d86ad.css"]}},
    }]
